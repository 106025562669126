/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }

body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

@import '~font-awesome/css/font-awesome.css';
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;1,100;1,200;1,300;1,400;1,500&display=swap');

:root {
    --white: #fafafa;
    --navbarDark: #18181b;
}
